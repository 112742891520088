.loader-master {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5b0;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.not-found-master {
  height: 100vh;
  display: 'flex';
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  text-align: center;
  padding: 0 20px;
}

/* Common Table Styles */
.table-name-master {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-transform: capitalize;
}
.table-name-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3966b8;
  margin-right: 10px;
  color: #ffffff;
}

/* Common Add User Button */
.add-btn-master {
  width: 140px;
  height: 34px;
  margin-top: 13px;
  margin-bottom: 15px;
  margin-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #bf0000;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  border-radius: 8px;
  cursor: pointer;
}

.add-btn-txt {
  font-size: 12px !important;
  font-family: 'Red Hat Display Bold';
  color: #ffffff;
  line-height: 24px;
  font-weight: 600 !important;
}
