/* Fonts sections */
@font-face {
  font-family: 'Red Hat Display';
  src: local('arial-light'),
    url('./assets/font/RedHatDisplay-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Red Hat Display Italic';
  src: local('arial-light'),
    url('./assets/font/RedHatText-MediumItalic.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Red Hat Display Bold';
  src: local('Red Hat Display Bold'),
    url('./assets/font/RedHatDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Red Hat Display Medium';
  src: local('Red Hat Display Medium'),
    url('./assets/font/RedHatDisplay-SemiBold.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Red Hat Display SemiBold';
  src: local('Red Hat Display SemiBold'),
    url('./assets/font/RedHatDisplay-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Red Hat Display Regular';
  src: local('Red Hat Display Regular'),
    url('./assets/font/RedHatDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
}

body {
  margin: 0;
  font-size: 0.9em !important;
  background-color: #f8f8fa !important;
  -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
